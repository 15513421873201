// register component as global
// define component naming with kebab-case
const topProductsSliderCmp = Vue.component("topproducts-slider-component", {
  template: `<el-row v-if="isEnabledProductSlider">
    <el-col style="padding: 5px 0px">
      <div class="owl-carousel owl-theme topproducts-slider">
        <div
          class="item"
          v-for="(item,index) in topItems"
          :key="index"
          :data-id="item.id"
        >
          <div class="most-play-game">
            <div class="most-play-game-image-container">
              <img :src="item.goods_pic" />
            </div>
            <div class="most-play-game-title-container">
              <p class="most-play-game-title">{{item.goods_sn}}</p>
              <p class="most-play-game-desc">{{item.shop_name}}</p>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
`,
  data() {
    return {
      isEnabledProductSlider: uiConfig.grabOrderPage.isEnabledProductSlider,
      topItems: [],
    };
  },
  methods: {
    async topProducts() {
      const self = this;

      const res = await axios.get("/index/order/topProducts");

      console.log("topProducts", res);
      if (res.data.code == 0) {
        self.topItems = res.data.data;

        setTimeout(() => {
          $(".owl-carousel.topproducts-slider").owlCarousel({
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            margin: 10,
            nav: false,
            dots: false,
            //autoWidth: true,
            mergeFit: true,
            item: 3,
            responsive: {
              0: {
                items: 3,
              },
            },
          });
        }, 2000);
      } else {
        that.$message({
          message: self.translateWord(res.data.info),
          type: "warning",
        });
      }
    },
  },
  mounted() {
    if (this.isEnabledProductSlider) {
      this.topProducts();
    }
  },
});
